import bugsnag from '@bugsnag/js';
import { getScriptResources } from '../utilities/debugging';

const isStaging = !!window && /(dev\.|stage([0-9]+)?\.)/.test(window.location.hostname);

const bugsnagClient = bugsnag({
	apiKey: '8afcbc6036f5c8e64a092426efbb4389',
	consoleBreadcrumbsEnabled: process.env.NODE_ENV === 'production',
	filters: [
		/^password$/i,
		/^cardnum$/i,
		/^cvv$/i,
		/^card_number$/i,
		/^card_cvn$/i,
		/^cc_number_hash$/i,
		/^password_hash$/i,
		/^apikey$/i,
		/^newpassword$/i,
		/^token$/i
	],
	releaseStage: process.env.NODE_ENV,
	notifyReleaseStages: ['production'],
	beforeSend: function (report) {

		if (isStaging) return false;

		if (!report.metaData) {
			report.metaData = {};
		}

		for (let x = 0; x < report.stacktrace.length; x++) {
			const item = report.stacktrace[x];
			if (item.method && item.method.indexOf("er.applyChanges") !== -1 && report.errorMessage.indexOf("Cannot read properties of undefined (reading 'split')") !== -1) return false; // VWO issues
			if (item.method && item.method.indexOf("applyChanges") !== -1 && report.errorMessage.indexOf("undefined is not an object (evaluating 'e.split')") !== -1) return false; // VWO issues
			if (item.file.indexOf("moz-extension") !== -1) return false; // Firefox extensions
			if (item.file.indexOf("honeypay") !== -1) return false; // Honey
			if (item.file.indexOf("s7.addthis.com") !== -1) return false; // AddThis
			if (item.file.indexOf("proxydirectory") !== -1) return false; // CJ
			if (item.file.indexOf("apps.bazaarvoice.com") !== -1) return false; // BazaarVoice
			if (item.file.indexOf("blain-cdn") !== -1) return false;
			if (item.file.indexOf("criteo.net") !== -1) return false;
			if (item.file.indexOf("googleadservices.com") !== -1) return false;
			if (item.file.indexOf("auto-parts.js") !== -1 &&
				report.errorMessage.indexOf('Blain is not defined') !== -1) return false; // Ignore Blain is not defined errors on the auto parts JS
		}

		const ignoredErrorMessages = [
			'Blocked a frame with origin "https://www.farmandfleet.com"', // Ignore common CORS errors
			'matchMedia not present', // Ignore matchMedia errors
			'Load timeout for modules:', // Ignore load timeout errors
			'bv-analytics', // Ignore bv-analytics errors
			'could not load https://apps.bazaarvoice.com/', // Ignore bv errors
			'script timeout https://apps.bazaarvoice.com/', // Ignore bv errors
			'A network error has occurred', // Ignore network errors
			'The network connection was lost', // Ignore network errors
			'UET is not defined', // Bing conversion tracking doesn't check that its bat.js script actually loaded
			'setIOSParameters', // We get a lot of these, but nobody knows what it is. Comes from a third-party script
			'Rejection reason was not an Error', // A third-party script that is sending a rejection reason of 'afterConsentCallbackError'
			'IDBFactory.open() called in an invalid security context', // This happens if a cross-domain iframe is attempted on Safari
			'pptag is not defined', // Related to the PayPal SDK and apparently not controllable
			'Can\'t find variable: pptag', // Related to the PayPal SDK and apparently not controllable
			'The operation is insecure', // Ignorable errors related to CORS, etc.
			'ResizeObserver loop completed with undelivered notifications', // Listrak GXP appears to produce this
			'Can\'t find variable: _AutofillCallbackHandler', // Possibly coming from Facebook pixel
			'window.cobrowseIOgetRedactedElementsBounds is not a function', // Possibly coming from Microsoft Clarity
			'KAMPYLE_ONSITE_SDK is not defined', // Medallia
			'Can\'t find variable: UET', // Bing
			'Failed to fetch', // Pinterest
			'Can\'t find variable: gmo', // Unknown, apparently non-actionable
		];

		const inErrorMessage = content => report.errorMessage.indexOf(content) !== -1;
		if (ignoredErrorMessages.some(inErrorMessage)) {
			return false;
		}

		if (report.errorClass === "TypeError" && report.errorMessage === "cancelled") return false; // Ignore 'cancelled' errors
		if (report.errorClass === "TypeError" && report.errorMessage === "Load failed") return false; // Ignore 'Load failed' errors
		if (report.request.url.indexOf("/products/") !== -1 && inErrorMessage('Blain is not defined')) return false; // Ignore Blain load errors on PDP
		if (report.request.url.indexOf("/products/") !== -1 && inErrorMessage('jQuery is not defined')) return false; // Ignore jQuery load errors on PDP

		const isNonErrorPromiseRejection = inErrorMessage('Rejection reason was not an Error');
		const rejectionReason = report?.metaData?.promise?.['rejection reason'];
		if (isNonErrorPromiseRejection && rejectionReason != null) {
			// ignore rejections with specific reason messages
			if (['undefined (or null)', 'The call to the server was not successful.'].includes(rejectionReason)) {
				return false;
			}

			// We see a number of these come in from some third-party script
			if (typeof rejectionReason === 'object' && rejectionReason.isTrusted === true) {
				return false;
			}
		}

		if (report.request.url.indexOf("/products/") !== -1) {
			report.context = "PDP";
			report.metaData = {
				...report.metaData,
				fullUrl: report.request.url,
				message: report.errorMessage
			};
			report.request.url = "/products/";
		}

		report.metaData.scriptsLoaded = getScriptResources();
	}
});

export default bugsnagClient;